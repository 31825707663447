import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://workers.mlsgame.com/bend',
  // baseURL: 'http://192.168.1.16:8010/bend'
})
// Token gönderir
axiosIns.interceptors.request.use(config => {
  const tokenData = localStorage.getItem('userToken')
  if (tokenData) {
    // eslint-disable-next-line no-param-reassign
    config.headers.authorization = `Bearer ${tokenData}`
  }
  return config
})

// Autorize Hatası
axiosIns.interceptors.response.use(null, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('userToken')
    // eslint-disable-next-line no-restricted-globals
    location.href = '/login'
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
